import { useAuth0 } from '@auth0/auth0-react';
import React, { createContext, ReactNode, useCallback, useContext, useEffect, useMemo, useState } from 'react';

const TokenContext = createContext({
  simpleToken: undefined,
  richToken: undefined,
  loading: true,
});

interface TokenProps {
  children: ReactNode;
}

export const TokenProvider = ({ children }: TokenProps) => {
  const { getAccessTokenSilently, getIdTokenClaims } = useAuth0();
  const [simpleToken, setSimpleToken] = useState(null);
  const [richToken, setRichToken] = useState(null);
  const [loading, setLoading] = useState(true);
  const contextValue = useMemo(() => ({ simpleToken, richToken, loading }), [simpleToken, richToken, loading]);
  const fetchToken = useCallback(async () => {
    // if (isAuthenticated) {
    try {
      const accessToken = await getAccessTokenSilently();
      setSimpleToken(accessToken);

      const claims = await getIdTokenClaims();
      // eslint-disable-next-line no-underscore-dangle
      setRichToken(claims?.__raw);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error('Failed to fetch token', error);
    }
    // }
    setLoading(false);
  }, [getAccessTokenSilently, getIdTokenClaims]);

  useEffect(() => {
    fetchToken();
  }, [fetchToken]);

  return <TokenContext.Provider value={contextValue}>{children}</TokenContext.Provider>;
};

export const useAuth0Token = () => useContext(TokenContext);
