import React from 'react';
import { TextVariant } from '@naf/text';
import { useHistory } from 'react-router';
import { UserInteractionVariant } from '@naf/user-interaction';
import { SiteStructureType } from '../../../../types/siteStructureType';
import { Layout, MainContent } from '../../components/layout/Layout';
import FAQLink from './FAQLink';
import { FaqAppPropertiesType } from '../../../../types/CategoryAppType';
import { ThreeColumnBlock } from '../../components/SectionBlocks/blocks/ThreeColumnBlock';
import { useDocumentUrlWithNode } from '../../hooks/useDocumentUrl';
import ErrorBoundary from '../../components/error-component/ErrorBoundary';
import { InternalSearchCardType } from '../../components/layout/InternalSearchMetaData';
import * as S from './Styles';

type Props = {
  data: SiteStructureType;
  appData: {
    properties: FaqAppPropertiesType;
  };
};

const FAQ = ({ data, appData }: Props) => {
  const { threeColumnBlock, faq: faqList, warningBlockTimeControlledWithLink } = appData.properties;

  const history = useHistory();
  const internalLink = useDocumentUrlWithNode(
    warningBlockTimeControlledWithLink && warningBlockTimeControlledWithLink.internalLink,
  );
  return (
    <Layout
      title={data.seoConfig?.title || data.name}
      description={data.seoConfig?.introduction || data.ingress || ''}
      gtmArgs={{ page_type: 'Hjelp og kontakt', pageCategory: data.slug, contentId: data.name }}
      internalSearchMetaData={{ cardType: InternalSearchCardType.CategoryApp }}
    >
      <S.StyledSectionHeader title="Hjelp og kontakt" />
      <ErrorBoundary>
        <MainContent>
          <S.Wrapper>
            {warningBlockTimeControlledWithLink && (
              <S.StyledUserInteraction
                variant={UserInteractionVariant.Warning}
                maxWidth={554}
                buttonLink={{
                  text: warningBlockTimeControlledWithLink.linkText,
                  href: warningBlockTimeControlledWithLink.externalLink?.url,
                  onClick: internalLink
                    ? () => {
                        history.push(internalLink);
                        return false;
                      }
                    : undefined,
                }}
              >
                {warningBlockTimeControlledWithLink.text}
              </S.StyledUserInteraction>
            )}
            {threeColumnBlock?.map((block) => (
              <ThreeColumnBlock block={block} key={block.title} />
            ))}
            <S.ContentHeader variant={TextVariant.Header2}>Hva trenger du hjelp med?</S.ContentHeader>
            <S.TopicWrapper>
              {faqList?.map((faq) => (
                <FAQLink key={faq.title} faq={faq} />
              ))}
            </S.TopicWrapper>
          </S.Wrapper>
        </MainContent>
      </ErrorBoundary>
    </Layout>
  );
};

export default FAQ;
