'use-client';

import React, { useState, useEffect, useCallback } from 'react';
import styled from 'styled-components';
import { breakpoints, spacing } from '@naf/theme';
import { autoGravity } from '@cloudinary/url-gen/qualifiers/gravity';
import { dpr } from '@cloudinary/url-gen/actions/delivery';
import { fill } from '@cloudinary/url-gen/actions/resize';
import { useWindowResize } from '../../../../hooks/useWindowResize';

import { useCloudinary } from '../../../../hooks/useCloudinary';
import { useDevicePixelRatio } from '../../../../hooks/useDevicePixelRatio';
import { TextAndBackgroundImageBlock } from '../../../../../../types/ScrollableTextBlocksWithBackgroundImagesType';

export const ParallaxItemImage = ({
  item,
  showImage,
  setTopMargin,
  setBottomMargin,
}: {
  item: TextAndBackgroundImageBlock;
  showImage?: boolean;
  setTopMargin?: (margin: number) => void;
  setBottomMargin?: (margin: number) => void;
}) => {
  const cld = useCloudinary();
  const devicePixelRatio = useDevicePixelRatio();
  const [maxHeightMobile, setMaxHeightMobile] = useState('100vh');

  const [image1, setImage1] = useState(
    (item?.backgroundImage?.public_id &&
      cld
        .image(item?.backgroundImage.public_id)
        .resize(fill().width(1120).gravity(autoGravity()))
        .delivery(dpr(devicePixelRatio))
        .quality('auto:best')
        .format('auto')) ||
      undefined,
  );

  const properlySetMargin = useCallback(() => {
    if (!setTopMargin && !setBottomMargin) return;
    const image = document.getElementById(`image_${item._key}`);
    if (!image) return;

    const imageHeight = image.clientHeight;
    const containerHeight = image.parentElement.parentElement.clientHeight;

    const margin = (containerHeight - imageHeight) / 2;

    if (setTopMargin) setTopMargin(margin);
    if (setBottomMargin) setBottomMargin(margin);
  }, [item._key, setTopMargin, setBottomMargin]);

  const setResponsiveImage = useCallback(() => {
    if (typeof window !== 'undefined') {
      const image =
        window.innerWidth < parseInt(breakpoints.s, 10) && item && item.backgroundImageMobile
          ? item.backgroundImageMobile
          : item.backgroundImage;
      if (image && image.public_id) {
        // Calculate margins
        const marginWidth = window.innerWidth < parseInt(breakpoints.l, 10) ? 48 : 0;

        if (window.innerWidth > parseInt(breakpoints.s, 10)) {
          let width = Math.round(window.innerWidth - marginWidth);

          // If screen is large, use max 1120 (container) as width
          if (window.innerWidth > parseInt(breakpoints.s, 10)) {
            width = Math.min(width, 1120);
          }

          setImage1(
            cld
              .image(image.public_id)
              .resize(fill().width(width).gravity(autoGravity()))
              .delivery(dpr(devicePixelRatio))
              .quality('auto:best')
              .format('auto'),
          );
          properlySetMargin();
        } else {
          // If image is mobile view
          const screenRatio = Math.round(window.innerHeight / window.innerWidth);
          let height = screenRatio < 2 ? window.innerHeight : Math.round(0.9 * window.innerHeight);
          setMaxHeightMobile(`${height}px`);
          if (setTopMargin || setBottomMargin) {
            if (setTopMargin) setTopMargin(height / 30);
            if (setBottomMargin) setBottomMargin(100);
          }

          // Use image height if not small screen
          if (image.height && image.height < window.innerHeight) height = image.height;
          setImage1(
            cld
              .image(image.public_id)
              .resize(fill().height(height).gravity(autoGravity()))
              .delivery(dpr(devicePixelRatio))
              .quality('auto:best')
              .format('auto'),
          );
        }
      }
    }
  }, [cld, devicePixelRatio, item, setTopMargin, setBottomMargin, properlySetMargin]);

  useEffect(() => {
    setResponsiveImage();
  }, [setResponsiveImage]);

  useWindowResize(() => {
    setResponsiveImage();
    properlySetMargin();
  });

  return image1 ? (
    <ImageWrapper>
      <Image
        $imageOriginalWidth={item?.backgroundImage?.width}
        $maxHeightMobile={maxHeightMobile}
        id={`image_${item._key}`}
        className={`image_${item._key} ${showImage ? 'show-image' : ''}`}
        src={image1.toURL()}
        alt={item?.backgroundImage.alt || item.title}
        onLoad={properlySetMargin}
      />
    </ImageWrapper>
  ) : null;
};

const ImageWrapper = styled.div`
  max-height: 100%;
  position: absolute;

  @media (max-width: ${breakpoints.l}) {
    max-height: calc(100vh - ${spacing.space48});
  }
`;

const Image = styled.img<{ $imageOriginalWidth?: number; $maxHeightMobile?: string }>`
  max-height: 85vh;
  max-width: ${({ $imageOriginalWidth }) =>
    $imageOriginalWidth && $imageOriginalWidth < 1120 ? `${$imageOriginalWidth}px` : '100%'};

  opacity: 0;
  transition: opacity 0.5s ease-in-out;
  object-fit: cover;

  @media (max-width: ${breakpoints.s}) {
    max-width: fit-content;
    max-height: ${({ $maxHeightMobile }) => $maxHeightMobile || '100vh'};
  }

  &.show-image {
    opacity: 1;
    transition: opacity 0.5s ease-in-out;
  }
`;
