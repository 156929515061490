import React from 'react';
import styled from 'styled-components';
import { CardList } from '@naf/cards';
import useSelector from '../../redux/typedHooks';
import { cloudinaryImage } from '../../utils/imageUrl';
import { RelatedArticleType } from '../../../../types/articleType';
import FallbackImage from '../../../assets/images/fallback_image.png';
import { StyledLink } from '../styled-link/StyledLink';
import { ChargeTestType, DocumentTypeLabel, RangeTestType } from '../../../../types/testType';
import { RelatedTestCard } from '../related-cars/RelatedTestCard';
import { useDocumentUrlWithNode } from '../../hooks/useDocumentUrl';
import { ApplicationState } from '../../redux/modules/application';

interface Props {
  relatedContent: (RelatedArticleType | ChargeTestType | RangeTestType)[] | undefined;
  className?: string;
}

const RelatedItem = ({
  item,
  app,
}: {
  item: RelatedArticleType | ChargeTestType | RangeTestType;
  app: ApplicationState;
}) => {
  const itemUrl = useDocumentUrlWithNode(item) || '';

  if (item.type === 'nafnoArticle') {
    const itemImage =
      item && item.image && item.image.publicId
        ? cloudinaryImage(item.image.publicId, app, 160, 120, false, undefined, 'auto:low')
        : FallbackImage;

    return (
      item && (
        <RelatedListItem key={`related-${itemUrl}`}>
          <StyledLink to={itemUrl}>
            <CardList
              title={item.name}
              img={{
                src: itemImage,
                alt: item.image?.altText ? `${item.image.altText}` : '',
              }}
              memberLabel={
                item?.paywallDetails
                  ? {
                      labelText: item?.paywallDetails?.labelText || 'For medlemmer',
                      isEligible: item.paywallDetails?.isAccessibleForUser,
                    }
                  : undefined
              }
              isInternalLink
            >
              {item.ingress}
            </CardList>
          </StyledLink>
        </RelatedListItem>
      )
    );
  }
  if (
    item.type === DocumentTypeLabel.nafnoArticleCharge ||
    item.type === DocumentTypeLabel.nafnoArticleRange ||
    item.type === DocumentTypeLabel.articleRangeReferenceComponent ||
    item.type === DocumentTypeLabel.articleChargeReferenceComponent
  ) {
    return <RelatedTestCard carTest={item as ChargeTestType | RangeTestType} />;
  }
  return null;
};

const RelatedArticleList = ({ relatedContent, className }: Props) => {
  const application = useSelector((state) => state.application);
  return (
    (relatedContent && (
      <RelatedList className={className}>
        {relatedContent.map((item) => (
          <RelatedItem item={item} app={application} key={`related-${item.slug}`} />
        ))}
      </RelatedList>
    )) ||
    null
  );
};

export default RelatedArticleList;

const RelatedList = styled.ul`
  padding: 0;
  margin: 0;
`;

const RelatedListItem = styled.li`
  list-style-type: none;

  a {
    padding-bottom: 24px !important;
    margin-bottom: 24px !important;
  }
`;
