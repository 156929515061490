import { setCookie } from '../../lib/cookies';

const saveAbTestWeightFromServerIfApplicable = () => {
  try {
    // eslint-disable-next-line no-underscore-dangle
    setCookie('ab_test_weight', window?.__AB_TEST_WEIGHT__?.toString() || '0', 30, 'Lax', false);
  } catch {
    /* nevermind */
  }
};

export const trackCookieBannerClick = (consentOption: string, consentStatus?: string) => {
  setTimeout(() => {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: 'consentSelected',
      consentOption,
      consentStatus,
      consentPage: window.location.pathname,
      consentPageUrl: window.location.href,
    });
  }, 1500);

  if (consentStatus.indexOf('statistics:true') > -1) saveAbTestWeightFromServerIfApplicable();
};

export const trackCustomizedCookiesClick = (
  consentOption: string,
  preferences: boolean,
  statistics: boolean,
  marketing: boolean,
) => {
  setTimeout(() => {
    const consentStatus = `preferences:${preferences}|statistics:${statistics}|marketing:${marketing}`;
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: 'consentSelected',
      consentOption,
      consentStatus,
      consentPage: window.location.pathname,
      consentPageUrl: window.location.href,
    });
  }, 1500);

  if (statistics) saveAbTestWeightFromServerIfApplicable();
};
