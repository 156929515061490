import React, { ReactNode } from 'react';
import { TextList, TextListItem } from '@naf/text-list';
import { ArticleBlockContentGridCol } from '../types/components/ArticleBlockContentGridCol';

const ArticleWrapper = ({
  children,
  styleName,
  isArticle,
}: {
  children?: ReactNode;
  styleName: string;
  isArticle: boolean;
}) =>
  isArticle ? (
    <ArticleBlockContentGridCol className={`article_${styleName}`}>{children}</ArticleBlockContentGridCol>
  ) : (
    children
  );

export const BulletListComponent = ({
  children,
  context,
}: {
  children?: ReactNode;
  context?: 'article' | 'stripped';
}) => {
  const isArticle = context === 'article';
  return (
    <ArticleWrapper isArticle={isArticle} styleName="list">
      <TextList variant="bullet">
        {Array.isArray(children)
          ? children.map((c: any) => <TextListItem key={c.key}>{c.props.children}</TextListItem>)
          : null}
      </TextList>
    </ArticleWrapper>
  );
};
export const NumberListComponent = ({
  children,
  context,
}: {
  children?: ReactNode;
  context?: 'article' | 'stripped';
}) => {
  const isArticle = context === 'article';
  return (
    <ArticleWrapper isArticle={isArticle} styleName="list">
      <TextList variant="number">
        {Array.isArray(children)
          ? children.map((c: any) => <TextListItem key={c.key}>{c.props.children}</TextListItem>)
          : null}
      </TextList>
    </ArticleWrapper>
  );
};

export const CheckListComponent = ({
  children,
  context,
}: {
  children?: ReactNode;
  context?: 'article' | 'stripped';
}) => {
  const isArticle = context === 'article';
  return (
    <ArticleWrapper isArticle={isArticle} styleName="list">
      <TextList variant="check">
        {Array.isArray(children)
          ? children.map((c: any) => <TextListItem key={c.key}>{c.props.children}</TextListItem>)
          : null}
      </TextList>
    </ArticleWrapper>
  );
};

export const CloseListComponent = ({
  children,
  context,
}: {
  children?: ReactNode;
  context?: 'article' | 'stripped';
}) => {
  const isArticle = context === 'article';
  return (
    <ArticleWrapper isArticle={isArticle} styleName="list">
      <TextList variant="close">
        {Array.isArray(children)
          ? children.map((c: any) => <TextListItem key={c.key}>{c.props.children}</TextListItem>)
          : null}
      </TextList>
    </ArticleWrapper>
  );
};

export const BulletListWrapper = ({
  children,
  context,
}: {
  children?: ReactNode;
  context?: 'article' | 'stripped';
}) => <BulletListComponent context={context}>{children}</BulletListComponent>;

export const NumberListWrapper = ({
  children,
  context,
}: {
  children?: ReactNode;
  context?: 'article' | 'stripped';
}) => <NumberListComponent context={context}>{children}</NumberListComponent>;

export const CheckListWrapper = ({ children, context }: { children?: ReactNode; context?: 'article' | 'stripped' }) => (
  <CheckListComponent context={context}>{children}</CheckListComponent>
);
export const CloseListWrapper = ({ children, context }: { children?: ReactNode; context?: 'article' | 'stripped' }) => (
  <CloseListComponent context={context}>{children}</CloseListComponent>
);
