import styled, { css } from 'styled-components';
import { nafColor } from '@nafcore/theme';
import { breakpoints } from '@naf/theme';
import { GridRow } from '@naf/grid';

const Wrapper = styled.div`
  > p {
    font-size: 1.6rem;
    padding: 1em;
  }

  h1 {
    margin: 40px 0 16px;
  }
`;

const IngressWrapper = styled.div`
  margin-bottom: calc(64px - 24px);

  p {
    margin: 0;
  }

  @media (max-width: ${breakpoints.l}) {
    margin-bottom: calc(32px - 12px);

    p {
      font-size: 1.5rem;
    }
  }

  @media (max-width: ${breakpoints.m}) {
    p {
      font-size: 1.125rem;
    }
  }
`;

const CarFilterWrapper = styled.div`
  @media (min-width: ${breakpoints.l}) {
    width: calc(75% + 12px);
  }

  margin-top: -24px;
`;

const CarListWrapper = styled.div`
  width: 100%;

  @media (max-width: ${breakpoints.m}) {
    margin-top: 12px;
  }
`;

const DisclaimerWrapper = styled.div`
  @media (min-width: ${breakpoints.l}) {
    width: calc(75% + 12px);
  }

  margin-top: 30px;

  p {
    line-height: 1.5;
  }
`;

const LabelList = styled.ul`
  padding-left: 0;
  list-style: none;
  margin-top: 8px;
  font-size: 1rem;
`;

const LabelListElement = styled.li`
  display: inline-block;
  color: ${nafColor && nafColor.neutral.neutral6 ? nafColor.neutral.neutral6 : `#4D4D4D`};
  padding-right: 8px;

  &:not(:last-child)::after {
    content: '\\2022';
    color: #c1c0c1;
    padding-left: 8px;
  }

  &:last-child ::after {
    content: '';
  }
`;

const ImagePlaceholder = styled.div<{ width?: number; height?: number }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  min-width: 100%;
  width: 100%;
  overflow: hidden;
  border-radius: 2px;
  margin: 0;
  ${({ width }) =>
    width
      ? css`
          width: ${width}px;
          height: calc(${width}px / (16 / 9));
        `
      : css`
          width: 100%;
        `};
  ${({ height }) =>
    height &&
    css`
      height: ${height}px;
    `};
  position: relative;
  background-color: ${nafColor.neutral.neutral1};
  color: ${nafColor.neutral.neutral5};

  svg {
    transition: transform 0.6s cubic-bezier(0.23, 1, 0.32, 1);
  }

  z-index: 0;
`;

const PlaceholderTextElement = styled.span`
  color: ${nafColor.neutral.neutral5};
  transition: transform 0.6s cubic-bezier(0.23, 1, 0.32, 1);
  text-align: center;
`;

const LargeGrid = styled(GridRow)`
  @media (max-width: ${breakpoints.m}) {
    display: none;
  }
`;

const SmallGrid = styled(GridRow)`
  @media (width >= calc(${breakpoints.m} + 1px)) {
    display: none;
  }
`;

export default {
  Wrapper,
  CarFilterWrapper,
  CarListWrapper,
  LabelList,
  LabelListElement,
  IngressWrapper,
  ImagePlaceholder,
  PlaceholderTextElement,
  DisclaimerWrapper,
  LargeGrid,
  SmallGrid,
};
