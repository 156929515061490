import React from 'react';
import { PortableText } from '@portabletext/react';
import styled, { css } from 'styled-components';
import { marks } from './components/marks';
import { types } from './components/types';
import {
  BulletListWrapper,
  NumberListWrapper,
  CheckListWrapper,
  CloseListWrapper,
} from './components/list/listComponent';
import { ContainerSerializer } from './components/Container';
import { block } from './components/types/components/BlockSerializer';
import { StyledArticleContentGrid } from '../../pages/Article/Styles';

const BlockContent = ({
  value,
  context,
  isAccessibleForUser,
}: {
  value?: any;
  context?: 'article' | 'stripped';
  isAccessibleForUser?: boolean;
}) => {
  const components = React.useMemo(
    () => ({
      types: types(context),
      list: {
        bullet: (props: any) => BulletListWrapper({ ...props, context }),
        number: (props: any) => NumberListWrapper({ ...props, context }),
        check: (props: any) => CheckListWrapper({ ...props, context }),
        close: (props: any) => CloseListWrapper({ ...props, context }),
      },
      block: block(context),
      marks,
      container: ContainerSerializer(context),
    }),
    [context],
  );

  const filteredComponents = React.useMemo(
    () =>
      value?.filter(
        (component: any) =>
          [...Object.keys(components.types), 'block', 'span'].includes(component._type) &&
          typeof component._type !== 'undefined',
      ),
    [value, components.types],
  );

  if (!filteredComponents || filteredComponents.length === 0) {
    return null;
  }

  const paywallIndex = filteredComponents.findIndex((e) => e._type === 'paywallReference');
  const hasPaywall = paywallIndex !== -1;

  if (hasPaywall) {
    const abovePaywall = isAccessibleForUser
      ? filteredComponents.slice(0, paywallIndex)
      : filteredComponents.slice(0, paywallIndex + 1);
    const belowPaywall = filteredComponents.slice(paywallIndex + 1);
    return context === 'article' ? (
      <>
        <ContentAbovePaywallContainer>
          <StyledArticleContentGrid>
            <PortableText value={abovePaywall} components={components} />
          </StyledArticleContentGrid>
        </ContentAbovePaywallContainer>
        <PayWalledContentContainer className="paywall" $isAccessibleForUser={isAccessibleForUser}>
          <StyledArticleContentGrid>
            <PortableText value={belowPaywall} components={components} />
          </StyledArticleContentGrid>
        </PayWalledContentContainer>
      </>
    ) : (
      <>
        <ContentAbovePaywallContainer>
          <PortableText value={abovePaywall} components={components} />
        </ContentAbovePaywallContainer>
        <PayWalledContentContainer className="paywall" $isAccessibleForUser={isAccessibleForUser}>
          <PortableText value={belowPaywall} components={components} />
        </PayWalledContentContainer>
      </>
    );
  }

  return context === 'article' ? (
    <StyledArticleContentGrid>
      <PortableText value={filteredComponents} components={components} />
    </StyledArticleContentGrid>
  ) : (
    <PortableText value={filteredComponents} components={components} />
  );
};

export default BlockContent;

const ContentAbovePaywallContainer = styled.div`
  position: relative;
`;

const PayWalledContentContainer = styled.div<{ $isAccessibleForUser: boolean }>`
  ${({ $isAccessibleForUser }) =>
    !$isAccessibleForUser
      ? css`
          display: none;
        `
      : null}
`;
