import styled from 'styled-components';
import { breakpoints, spacing } from '@naf/theme';
import { nafColor } from '@nafcore/theme';
import { Grid, GridCol } from '@naf/grid';

// Need this empty wrapper for SchemaArticle script
export const Wrapper = styled.div``;

export const StyledArticleContentGrid = styled(Grid)`
  margin-bottom: ${spacing.space16};

  @media (max-width: ${breakpoints.m}) {
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }
`;

export const BreadCrumbsGrid = styled(Grid)`
  @media (max-width: ${breakpoints.m}) {
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }
`;

export const BreadCrumbGridCol = styled(GridCol)`
  grid-column: 3 / -3;
  margin-bottom: ${spacing.space48};

  @media (max-width: ${breakpoints.m}) {
    grid-column: 1 / -1;
    margin-bottom: ${spacing.space32};
  }
`;

export const ArticleBlockWrapper = styled.div`
  // Styling for spacing of elements specific to article. Perhaps to be moved to serializers, if possible
  h2,
  h3,
  p {
    margin: 0;
  }

  .article_h2,
  .article_h3 {
    margin-bottom: -${spacing.space16};
  }

  // Headers that directly precede numbered lists require an actual margin
  .article_h2:has(+ * > .numberedList),
  .article_h3:has(+ * > .numberedList) {
    margin-bottom: ${spacing.space16};
  }

  // for table of contents with navbar
  h2 {
    scroll-margin-top: ${spacing.space80};
  }

  /* Specific checks to determine spacing if elements are in certain order
    article_normal here is mostly used for normal text, aka paragraph tags
    This is to create extra spacing when other elements follow blocks of text/paragraph. 
    This should apply extra margin to top of elements following a paragraph, except other paragraphs
  */
  .article_normal
    + :not(
      .article_normal,
      .article_list,
      .list,
      .article_h3,
      .article_h2,
      .CtaButton,
      .videoBlock,
      .blockAccordion,
      .numberedList,
      .internalLink
    ) {
    margin-top: ${spacing.space32};

    @media (max-width: ${breakpoints.m}) {
      margin-top: ${spacing.space24};
    }
  }

  /* Selects .blockAccordion that is a direct sibling of .article_h2 or .article_h3 and is not the first child */
  .article_h2 + .blockAccordion,
  .article_h3 + .blockAccordion {
    margin-top: 0; /* Reset margin */
  }

  /* Apply margin to all other instances of .blockAccordion */
  .blockAccordion {
    margin-top: -${spacing.space32};

    @media (max-width: ${breakpoints.m}) {
      margin-top: -${spacing.space24};
    }
  }

  .numberedList {
    margin-top: -${spacing.space32};

    @media (max-width: ${breakpoints.m}) {
      margin-top: -${spacing.space24};
    }
  }

  .list + :not(.article_normal, .list, .article_h3) {
    margin-top: ${spacing.space32};

    @media (max-width: ${breakpoints.m}) {
      margin-top: ${spacing.space24};
    }
  }
`;

export const ContentCol = styled.div`
  // Firefox overflow bugfix
  max-width: 100%;
  grid-column: 3 / -3;

  @media (max-width: ${breakpoints.m}) {
    grid-column: 1 / -1;
  }
`;

export const BottomContentCol = styled.div`
  // Firefox overflow bugfix
  max-width: 100%;
  grid-column: 3 / -3;

  @media (max-width: ${breakpoints.m}) {
    grid-column: 1 / -1;
  }
`;

export const RelatedGridCol = styled.div`
  grid-column: 3 / -3;

  h3 {
    margin-top: 0;
  }

  @media (max-width: ${breakpoints.m}) {
    grid-column: 1 / -1;
  }
`;

export const HRLine = styled.hr`
  border: 0;
  border-top: 3px solid ${nafColor && nafColor.signature.yellow ? nafColor.signature.yellow : `#FFDB16`};
  width: 96px;
  margin: ${spacing.space4} 0 ${spacing.space48};
  color: ${nafColor && nafColor.signature.yellow ? nafColor.signature.yellow : `#FFDB16`};
`;

export const ReadMoreHRLine = styled(HRLine)``;

export const ScoreWrapper = styled.div`
  margin-bottom: 48px;

  h3 {
    margin-bottom: 15px;
  }
`;

export const ScoreIconWrapper = styled.div`
  display: flex;
  font-size: 1rem;
  line-height: 1.5rem;
  flex-direction: row;
  margin-bottom: 25px;

  svg {
    width: 24px;
    margin-right: 13px;
    padding: 0;
  }

  b {
    font-weight: 500;
  }
`;
