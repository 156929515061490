import React from 'react';
import MobileLights from '../../../../../../assets/decorations/christmas/MobileLights.svg?url';
import Lights from '../../../../../../assets/decorations/christmas/Lights.svg?url';
import SmallSnow from '../../../../../../assets/decorations/christmas/SmallSnow.svg?url';
import LargeSnow from '../../../../../../assets/decorations/christmas/LargeSnow.svg?url';
import * as S from './Styles';

export const Christmas = ({ isScrolled }: { isScrolled?: boolean }) => (
  <S.ChristmasWrapper $isScrolled={isScrolled}>
    <S.ScaledContent $isScrolled={isScrolled}>
      <S.LightsWrapper>
        <S.MobileWrapper>
          <img src={MobileLights} alt="Dekorasjon av navigasjon" />
        </S.MobileWrapper>
        <S.DesktopWrapper>
          <img src={Lights} alt="Dekorasjon av navigasjon" />
        </S.DesktopWrapper>
      </S.LightsWrapper>
      <S.SnowContainer>
        <S.SmallSnowWrapper>
          <img src={SmallSnow} alt="Dekorasjon av navigasjon" />
        </S.SmallSnowWrapper>
        <S.LargeSnowWrapper>
          <img src={LargeSnow} alt="Dekorasjon av navigasjon" />
        </S.LargeSnowWrapper>
      </S.SnowContainer>
    </S.ScaledContent>
  </S.ChristmasWrapper>
);
