import React from 'react';
import styled from 'styled-components';
import { Text } from '@naf/text';
import { Grid, GridRow, GridCol } from '@naf/grid';
import { nafColor } from '@nafcore/theme';
import { breakpoints, spacing } from '@naf/theme';
import { Accordion } from '@naf/accordion';
import { Link } from 'react-router-dom';

const FooterContent = styled.nav`
  overflow: hidden;
  color: ${nafColor && nafColor.signature.white ? nafColor.signature.white : `#FFFFFF`};
  max-width: 1216px;
  margin: auto;
  box-sizing: border-box;
  padding: 40px 48px 64px;

  @media (max-width: ${breakpoints.l}) {
    padding: 32px 24px 64px;
  }
`;

const FooterWrapper = styled.footer`
  background-color: ${nafColor && nafColor.primary.spruce ? nafColor.primary.spruce : `#062E2A`};
  min-height: 480px;
  z-index: 2;
  margin-top: auto;
  p {
    margin: 0px;
  }
`;

const StyledGrid = styled(Grid)`
  overflow: hidden;
`;

const ExpandedFooter = styled(GridRow)`
  @media (max-width: calc(${breakpoints.m} - 1px)) {
    display: none !important;
  }
`;

const MobileFooter = styled.div`
  grid-column: 1 / -1;
  display: flex;
  flex-direction: column;
  gap: 32px !important;

  @media (min-width: ${breakpoints.m}) {
    display: none !important;
  }
`;

const StyledAccordion = styled(Accordion)`
  border-color: rgba(255, 255, 255, 0.12);

  section {
    padding-bottom: 24px !important;
  }
`;

const StyledHeader = styled(Text)`
  margin: 20px 0 24px;

  @media (max-width: ${breakpoints.l}) {
    margin-top: 32px;
  }
`;

const PrivacyInfoWrapper = styled.div`
  border-top: 1px solid ${nafColor && nafColor.primary.park ? nafColor.primary.park : `#267C4F`};
  padding-top: 20px;
  display: flex;
  align-items: baseline;
  justify-content: space-between;

  p {
    margin-right: 40px;
    line-height: 2;
  }

  ul {
    li {
      display: inline-block;
      margin-left: ${spacing.space24};

      &:first-child {
        margin-left: 0;
      }
    }
  }
  @media (max-width: ${breakpoints.m}) {
    flex-direction: column;
    padding-top: 24px;
    justify-content: start;

    p {
      margin: 0 0 12px;
    }
  }
`;

const IconWrapper = styled.a`
  border-radius: 50%;
  border: 1px solid #fff;
  display: bl;
  width: 40px;
  height: 40px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;

  &:not(:first-child) {
    margin-left: 12px;
  }

  &:hover {
    border-color: #d3eac2;
    svg path {
      fill: #d3eac2 !important;
    }
  }
`;

const SizedIcon = styled.span`
  padding: 0;
  margin: 0;
  height: 20px;
  svg {
    width: 25px;
  }
`;

const Bottom = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: ${spacing.space64} 0 0px;
  border-top: 1px solid ${nafColor && nafColor.primary.park ? nafColor.primary.park : `#267C4F`};
  @media (max-width: ${breakpoints.l}) {
    margin-bottom: 0;
  }

  @media (max-width: ${breakpoints.m}) {
    flex-wrap: wrap;
    margin-top: ${spacing.space32};
  }
`;

const AddressInfo = styled.div`
  margin-top: ${spacing.space32};
  margin-left: ${spacing.space48};

  p {
    font-size: 1rem;
  }

  @media (max-width: ${breakpoints.m}) {
    margin-left: 0;
    width: 100%;
  }
`;

const Icons = styled.div`
  display: flex;
  justify-content: flex-end;
  align-self: flex-end;
  flex: 1;
`;

const LinkList = styled.ul`
  list-style-type: none;
  padding: 0;
  margin: 0;

  @media (max-width: ${breakpoints.m}) {
    margin: 0;
  }
`;

const ListElement = styled.li`
  span {
    font-weight: 500;
    margin-bottom: 12px;
    padding: 8px 0 8px !important;
  }

  p {
    font-weight: 500;
  }

  &:first-child {
    p {
      padding-top: 8px;
    }
  }

  &:not(:first-child) {
    p {
      padding-top: 16px;
    }
  }

  &:last-child {
    span {
      margin-bottom: 0;
    }
  }

  @media (max-width: ${breakpoints.m}) {
    span {
      margin-bottom: 8px;
    }
    &:not(:first-child) {
      p {
        padding-top: 8px;
      }
    }
  }
`;

const MemberWrapper = styled.div`
  text-align: right;
  margin-top: 32px;

  @media (max-width: ${breakpoints.l}) {
    margin-top: 32px;
    text-align: left;
  }
  @media (max-width: ${breakpoints.m}) {
    padding-top: 8px;
    a {
      width: 100%;
    }
  }
`;

const MobileButtonWrapper = styled.div`
  button {
    width: 100%;
  }
`;

// eslint-disable-next-line react/jsx-props-no-spreading
const NAFLogo = styled((props) => <Link {...props} />)`
  height: 56px;
  margin-top: ${spacing.space32};
  img {
    height: 100%;
  }
`;

const ResponsiveGridCol = styled(GridCol)`
  @media (max-width: ${breakpoints.l}) {
    order: -1;
  }
`;

const HideableResponsiveGridCol = styled(ResponsiveGridCol)<{ $isHidden?: boolean }>`
  ${({ $isHidden }) => $isHidden && `display: none;}`};
`;

const HideableGridCol = styled(GridCol)<{ $isHidden?: boolean }>`
  ${({ $isHidden }) =>
    $isHidden &&
    `@media (max-width: ${breakpoints.l}) {
      display: none;
    }`};
`;

export default {
  FooterContent,
  FooterWrapper,
  StyledGrid,
  ExpandedFooter,
  MobileFooter,
  StyledAccordion,
  StyledHeader,
  PrivacyInfoWrapper,
  IconWrapper,
  Bottom,
  Icons,
  LinkList,
  ListElement,
  MemberWrapper,
  NAFLogo,
  ResponsiveGridCol,
  SizedIcon,
  AddressInfo,
  HideableResponsiveGridCol,
  HideableGridCol,
  MobileButtonWrapper,
};
