import React, { useState } from 'react';
import styled, { css } from 'styled-components';
import { CardList } from '@naf/cards';
import { Star } from '@styled-icons/material-outlined/Star';
import { Button } from '@naf/button';
import useSelector from '../../redux/typedHooks';
import { cloudinaryImage } from '../../utils/imageUrl';
import { RelatedArticleType } from '../../../../types/articleType';
import { useDocumentUrlWithNode } from '../../hooks/useDocumentUrl';
import S from '../../styles/articles/StyledArticle';
import FallbackImage from '../../../assets/images/fallback_image.png';
import { StyledLink } from '../styled-link/StyledLink';

const TestCollectionCard = ({ testItem }: { testItem: RelatedArticleType }) => {
  const { image, name, ingress, selectedscore, maxscore } = testItem;
  const application = useSelector((state) => state.application);
  const mainImage = image
    ? image.publicId && cloudinaryImage(image.publicId, application, 160, 120, false, undefined, 'auto:eco')
    : FallbackImage;
  const itemUrl = useDocumentUrlWithNode(testItem) || '';

  return (
    <StyledLink to={itemUrl}>
      <CardList
        title={name}
        img={{
          src: mainImage || '',
          alt: image?.altText || '',
        }}
        isInternalLink
      >
        <IconWrapper>
          {!!selectedscore && (
            <IconSet>
              <Star size={24} />
              <span>
                <b>{selectedscore} poeng</b> {!!maxscore && `av ${maxscore}`}
              </span>
            </IconSet>
          )}
          {ingress && !selectedscore && <div>{ingress}</div>}
        </IconWrapper>
      </CardList>
    </StyledLink>
  );
};

const Item = (testItem: RelatedArticleType) => {
  const { name } = testItem;
  return (
    <S.RelatedListItem key={`testCollectionItem-${name}`}>
      <TestCollectionCard testItem={testItem} />
    </S.RelatedListItem>
  );
};

export const TestCollection = ({ testCollection }: any) => {
  const [isOpen, setIsOpen] = useState(false);

  const visibleTests = testCollection.slice(0, 3);
  const restOfTests = testCollection.slice(3);

  return (
    <S.RelatedList>
      {visibleTests && visibleTests.map(Item)}
      {testCollection.length > 3 && (
        <>
          <ShowMoreWrapper
            $isHidden={!isOpen}
            $height={restOfTests.length * 240} // 240 is the height of the card ++, if size changes, this needs to be changed
          >
            {restOfTests.map(Item)}
          </ShowMoreWrapper>
          <Button variant="secondary" onClick={() => setIsOpen(!isOpen)}>
            {!isOpen ? 'Se alle +' : 'Skjul tester'}
          </Button>
        </>
      )}
    </S.RelatedList>
  );
};

const IconWrapper = styled.div`
  display: flex;
  font-size: 1rem;
  line-height: 1.5rem;
  flex-direction: column;

  svg {
    margin-right: 13px;
    padding: 0;
  }
`;

const IconSet = styled.div`
  display: flex;
  align-items: center;
  margin-right: 20px;
  padding-bottom: 5px;

  b {
    font-weight: 500;
  }
`;

const ShowMoreWrapper = styled.div<{ $isHidden?: boolean; $height: number }>`
  max-height: ${(props) => props.$height}px;
  transition: max-height 1s ease-in;

  ${({ $isHidden }) =>
    $isHidden &&
    css`
      max-height: 0;
      overflow: hidden;
    `}
`;
