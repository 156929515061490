import styled from 'styled-components';
import { Text } from '@naf/text';
import { breakpoints, spacing } from '@naf/theme';
import { SectionHeader } from '@naf/section-header';
import { Grid } from '@naf/grid';
import { UserInteraction } from '@naf/user-interaction';

export const Wrapper = styled.div`
  .threeColumnBlock {
    h2 {
      margin-top: ${spacing.space64};
    }
    h3 {
      font-size: 1.25rem;
    }
  }
`;

export const ContentHeader = styled(Text)`
  margin-top: ${spacing.space64};
`;

export const StyledUserInteraction = styled(UserInteraction)`
  margin-top: ${spacing.space64};
`;

export const TopicWrapper = styled(Grid)`
  grid-gap: 0 ${spacing.space32};
  max-width: 1024px;
  justify-content: space-between;
  margin-bottom: ${spacing.space80};
`;

export const StyledSectionHeader = styled(SectionHeader)`
  h1 {
    padding-top: ${spacing.space160};

    @media (max-width: ${breakpoints.l}) {
      padding-top: ${spacing.space120};
    }
    @media (max-width: ${breakpoints.m}) {
      padding-top: 95px;
    }
  }
`;
