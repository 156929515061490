import React, { useContext } from 'react';
import { Text, TextVariant } from '@naf/text';
import styled from 'styled-components';
import { formatDate } from '../../../../../utils/Dates';
import { DowngradeContext } from '../../../../../pages/downgrade/context/DowngradeContext';

interface TextWithEndDateProps {
  value: {
    text: string;
  };
}

export const TextWithEndDate = ({ value: { text } }: TextWithEndDateProps) => {
  const { endDate } = useContext(DowngradeContext);

  if (!endDate) return null;

  return (
    <Text variant={TextVariant.ArticleText}>
      {text}{' '}
      <BoldText tag="span" variant={TextVariant.ArticleText}>
        {formatDate(endDate)}
      </BoldText>
    </Text>
  );
};

export const TextWithEndDateSerializer =
  () =>
  ({ value }: TextWithEndDateProps) =>
    <TextWithEndDate value={value} />;

export const BoldText = styled(Text)`
  font-weight: 700;
`;
