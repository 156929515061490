export const populateVariantsWithBinMaps = <T extends { weight: number }>(variants: T[]) =>
  Object.values(
    variants.reduce(
      (acc, cur, currentIndex) => {
        acc.binMap[currentIndex] = {
          ...cur,
          lowerLimit: acc.accumulatedWeights,
          upperLimit: acc.accumulatedWeights + cur.weight,
        };
        acc.accumulatedWeights += cur.weight;
        return acc;
      },
      {
        accumulatedWeights: 0,
        binMap: {} as Record<number, T & { lowerLimit: number; upperLimit: number }>,
      },
    ).binMap,
  );
