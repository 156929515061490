import React, { useEffect, useMemo, useRef, useState } from 'react';
import { CampaignBanner as Banner, CampaignBannerColor, CampaignBannerVariant } from '@naf/banner';
import { dpr } from '@cloudinary/url-gen/actions/delivery';
import { useHistory, useLocation } from 'react-router';
import { PortableText } from '@portabletext/react';
import useSelector from '../../../redux/typedHooks';
import { useDocumentUrlWithNode } from '../../../hooks/useDocumentUrl';
import { CampaignBlockType } from '../../../../../types/campaignBlockType';
import { useWindowResize } from '../../../hooks/useWindowResize';
import { useDevicePixelRatio } from '../../../hooks/useDevicePixelRatio';
import { isInternalLink } from '../../../lib/isInternalLink';
import { useCloudinary } from '../../../hooks/useCloudinary';
import { useGTMDataLayer } from '../../../hooks/useGTMDataLayer';
import { getHostname } from '../../../lib/getHostname';

export const CampaignBlock = ({ data }: { data: CampaignBlockType }) => <CampaignBanner campaign={data} />;

interface CampaignBannerProps {
  campaign: CampaignBlockType;
}

export const CampaignBanner = ({
  campaign: {
    illustration,
    referenceComponent,
    image,
    blockType,
    label,
    title,
    outlineButton,
    blank,
    buttonText,
    url,
    ingress,
    anchorId,
    variant,
  },
}: CampaignBannerProps) => {
  const campaignUrl = useDocumentUrlWithNode(referenceComponent);

  const dataLayer = useGTMDataLayer();
  const location = useLocation();
  const history = useHistory();
  const baseUrl = useSelector((state) => state.application.baseUrl);

  const bannerRef = useRef<HTMLDivElement>(null);
  const devicePixelRatio = useDevicePixelRatio();
  const cld = useCloudinary();
  const [mainImage, setMainImage] = useState(
    (image && cld.image(image.publicId).delivery(dpr(devicePixelRatio)).quality('auto:eco').format('auto').toURL()) ||
      undefined,
  );

  const setResponsiveImage = useMemo(
    () => () => {
      if (image) {
        setMainImage(
          cld.image(image.publicId).delivery(dpr(devicePixelRatio)).quality('auto:eco').format('auto').toURL(),
        );
      }
    },
    [cld, image, devicePixelRatio],
  );

  useEffect(() => {
    setResponsiveImage();
  }, [setResponsiveImage]);

  useWindowResize(() => {
    setResponsiveImage();
  });

  const { isInternal, relativeLink } = isInternalLink(baseUrl, url);

  const onCtaClick = () => {
    let constructedUrl;
    const isInternalCTA = !!(isInternal || campaignUrl);
    // If internal
    if (isInternalCTA) {
      constructedUrl = relativeLink || campaignUrl;
    } else if (url) {
      // If external
      constructedUrl = url;
    }

    if (anchorId && constructedUrl) {
      constructedUrl = `${constructedUrl}#${anchorId}`;
    }

    dataLayer?.push({
      event: 'cta_click',
      cta_text: buttonText,
      cta_location: location.pathname,
      cta_url: constructedUrl,
      cta_hostname: isInternalCTA ? getHostname(baseUrl) : getHostname(url),
      ctaPosition: 'content',
    });

    if (blank) {
      window.open(constructedUrl);
      return;
    }
    if (isInternalCTA) {
      history.push(constructedUrl || '');
      return;
    }
    if (!isInternalCTA && url) {
      window.location.href = constructedUrl || '';
    }
  };

  return (
    <Banner
      color={blockType === 'secondary' ? CampaignBannerColor.Dew : CampaignBannerColor.Yellow20}
      variant={variant || CampaignBannerVariant.Large}
      label={label}
      title={title}
      ref={bannerRef}
      ctaButton={
        !outlineButton && buttonText
          ? {
              text: buttonText,
              onClick: onCtaClick,
              blank,
            }
          : undefined
      }
      secondaryButton={
        outlineButton && buttonText
          ? {
              text: buttonText,
              onClick: onCtaClick,
              blank,
            }
          : undefined
      }
      illustration={illustration}
      img={{
        src: mainImage || '',
        alt: image?.source_altText || '',
      }}
    >
      {ingress && <PortableText value={ingress} />}
    </Banner>
  );
};
