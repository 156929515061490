import React from 'react';
import styled from 'styled-components';
import { breakpoints, spacing } from '@naf/theme';
import { CloudinaryImageType } from '../../../../types/blockTypes';
import { ImageCaption } from '../ImageCaption';
import ResponsiveImage from '../image/ResponsiveImage';

export const ArticleTextStandardImage = ({
  image,
  captionMode = 'text-right',
}: {
  image?: CloudinaryImageType;
  captionMode?: 'text-right' | 'text-left';
}) => {
  const { publicId, caption, altText, photographer, copyright } = image || {};

  const shouldShowCaption = caption || photographer || copyright;

  return (
    (publicId && (
      <Wrapper className="articleTextStandardImage">
        <ArticleImageGridCol>
          <ImageWrapper>
            <ResponsiveImage imageId={publicId} altText={altText || caption} />
          </ImageWrapper>
        </ArticleImageGridCol>
        {shouldShowCaption && (
          <ArticleImageCaptionGridCol $captionMode={captionMode}>
            <ImageCaption caption={caption} captionId={publicId} photographer={photographer} copyright={copyright} />
          </ArticleImageCaptionGridCol>
        )}
      </Wrapper>
    )) ||
    null
  );
};

const Wrapper = styled.figure`
  display: grid;
  grid-auto-flow: column dense;
  grid-column: 1 / -1;
  grid-gap: 0 32px;
  grid-template-columns: repeat(12, 1fr);
  grid-template-rows: auto auto;
  margin: ${spacing.space32} 0;

  @media (max-width: ${breakpoints.m}) {
    margin: ${spacing.space24} 0;
  }
`;

const ArticleImageCaptionGridCol = styled.figcaption<{ $captionMode?: 'text-right' | 'text-left' }>`
  grid-column: ${({ $captionMode }) => ($captionMode === 'text-right' ? '11 / 13' : '1 / 3')};
  align-self: end;

  @media (max-width: ${breakpoints.m}) {
    grid-column: 1 / -1;
    grid-row: 2;
  }
`;

const ArticleImageGridCol = styled.div`
  grid-column: 3 / -3;

  @media (max-width: ${breakpoints.m}) {
    grid-column: 1 / -1;
    grid-row: 1;
  }
`;

const ImageWrapper = styled.div`
  margin: 0;
  line-height: 0;
  width: 100%;
  img {
    width: 100%;
  }

  @media (max-width: ${breakpoints.m}) {
    img {
      max-width: 100%;
      width: auto;
    }
  }
`;
