import { GridRow } from '@naf/grid';
import React, { useEffect, useState } from 'react';
import { BehaviorSubject, debounceTime, of } from 'rxjs';
import { catchError, distinctUntilChanged, map, switchMap } from 'rxjs/operators';
import { ajax } from 'rxjs/ajax';
import { TextVariant } from '@naf/text';
import { Message } from '@naf/input';
import { useForm } from 'react-hook-form';
import { Button } from '@naf/button';
import { useLocation } from 'react-router';
import { formattedPrice } from '../../../../../../lib/formattedPrice';
import { calculatorForm } from './calculatorForm';
import {
  FormSchema,
  LoanCalculatorHeadings,
  LoanOffer,
  LoanType,
  NordeaLoanType,
  NordeaLoanTypeRedirect,
} from '../../../../../../../../types/loanCalculatorTypes';
import S from './Styles';
import { useGTMDataLayer } from '../../../../../../hooks/useGTMDataLayer';
import { ArticleBlockContentGridCol } from '../ArticleBlockContentGridCol';

interface CarLoanCalculatorProps {
  value: {
    mode: LoanType;
  };
}

export const CarLoanCalculatorBlock = ({ value: { mode } }: CarLoanCalculatorProps) => {
  const loanTypeRedirectParameter = NordeaLoanTypeRedirect[mode];
  const [currentLoanOffer, setCurrentLoanOffer] = useState<LoanOffer | null>(null);
  const [errorText, setErrorText] = useState();
  const [subject$] = useState<BehaviorSubject<FormSchema>>(
    new BehaviorSubject<FormSchema>({
      downPayment: undefined,
      price: undefined,
      repaymentYears: undefined,
      mode,
    }),
  );

  const {
    control,
    handleSubmit,
    watch,
    getValues,
    formState: { errors, isDirty },
  } = useForm({ mode: 'onChange' });

  const price = watch('price');
  const downpayment = watch('downPayment');
  const repaymentYears = watch('repaymentYears');

  // Push the current form state to the observable stream
  useEffect(() => {
    if (errors && getValues && mode && subject$) {
      const initialFormValues = getValues();
      subject$.next({ ...initialFormValues, errors, mode });
    }
    return () => {};
  }, [getValues, errors, mode, subject$]);

  // Push the current form state to the observable stream
  useEffect(() => {
    if (errors && mode && subject$ && watch) {
      const subscription = watch((values) => {
        subject$.next({ ...values, errors, mode });
      });
      return () => subscription.unsubscribe();
    }
    return () => {};
  }, [watch, errors, mode, subject$]);

  // Subscribe to the observable stream and calculate the new loan based on last values
  useEffect(() => {
    const subscription = subject$
      .pipe(
        debounceTime(200),
        map((formValues) => formValues),
        distinctUntilChanged(),
        switchMap((formValues) => {
          const $price = parseInt(formValues.price || '0', 10);
          const $downPayment = parseInt(formValues.downPayment || '0', 10);
          if (
            formValues &&
            formValues.price &&
            formValues.downPayment &&
            $price > $downPayment &&
            $price - $downPayment < 9999000 &&
            Object.values(formValues.errors || {}).length === 0
          ) {
            return ajax<LoanOffer>({
              method: 'post',
              url: `https://api.billan.nordea.no/api/partner/naf/calculation`,
              body: {
                downPayment: formValues.downPayment,
                isMember: true,
                repaymentYears: formValues.repaymentYears,
                vehicleType: NordeaLoanType[formValues.mode],
                price: formValues.price,
              },
            }).pipe(
              map(({ response }) => setCurrentLoanOffer(response)),
              catchError((err) =>
                of(() => {
                  setErrorText(err);
                }),
              ),
            );
          }
          return of({});
        }),
      )
      .subscribe();

    return () => subscription.unsubscribe();
  }, [subject$]);

  const dataLayer = useGTMDataLayer();
  const location = useLocation();

  // Handle tracking and redirect to Nordea if necessary values are set in the form
  const onSubmit = (loanType: string) => () => {
    if (typeof window !== 'undefined' && price && downpayment && repaymentYears) {
      const constructedUrl = `https://billan.nordea.no/soknad/?produkt=${loanType}&bilpris=${price}&egenkapital=${downpayment}&nedbetalingstid=${repaymentYears}&partner=NAF&mobile=&email=&kampanjekode=#/skjema/start`;
      dataLayer?.push({
        event: 'cta_click',
        cta_text: 'Søk om lånet',
        cta_location: location.pathname,
        cta_url: constructedUrl,
        cta_hostname: 'billan.nordea.no',
        ctaPosition: 'content',
      });
      // Clear session storage when order has been created
      window.location.href = constructedUrl;
    }
  };

  const loanAmount = price - downpayment;
  const loanAmountIsFilled = !Number.isNaN(loanAmount);
  const numberOfErrors = Object.values(errors).length;

  const headingText = LoanCalculatorHeadings[mode];

  return (
    <S.Wrapper columns={8}>
      <S.Header variant={TextVariant.Header3}>{headingText}</S.Header>
      <GridRow columns={8}>
        <S.FormGridCol s="8" m="4" l="4" xl="4">
          <S.StyledForm
            onSubmit={() => {}}
            fieldComponents={calculatorForm}
            control={control}
            errors={errors}
            handleSubmit={handleSubmit}
          />
        </S.FormGridCol>
        <S.SummaryGridCol s="8" m="4" l="4" xl="4">
          <S.Summary>
            <S.MonthlyPriceLabel variant={TextVariant.BodyTextHeader}>Månedspris</S.MonthlyPriceLabel>
            <S.MonthlyPrice variant={TextVariant.Header2}>
              {(currentLoanOffer?.monthlyPayments && `${formattedPrice(currentLoanOffer?.monthlyPayments)} kr`) || ''}{' '}
            </S.MonthlyPrice>
            <S.SummaryDetails>
              <S.SummaryItem>
                <div>Lånebeløp</div>
                <div>{loanAmountIsFilled && loanAmount && `${formattedPrice(loanAmount)} kr`}</div>
              </S.SummaryItem>
              <S.SummaryItem>
                <div>Nominell rente</div>
                <div>
                  {currentLoanOffer?.nominalInterestRate &&
                    `${currentLoanOffer?.nominalInterestRate.toFixed(2).replace('.', ',')} %`}
                </div>
              </S.SummaryItem>
              <S.SummaryItem>
                <div>Effektiv rente</div>
                <div>
                  {currentLoanOffer?.effectiveInterestRate &&
                    `${currentLoanOffer?.effectiveInterestRate.toFixed(2).replace('.', ',')} %`}
                </div>
              </S.SummaryItem>
            </S.SummaryDetails>
          </S.Summary>
          <Button
            width="full"
            disabled={((!price || !downpayment || !repaymentYears) && isDirty) || numberOfErrors > 0}
            onClick={handleSubmit(onSubmit(loanTypeRedirectParameter))}
          >
            Søk om lånet
          </Button>
          {loanAmountIsFilled && loanAmount < 10000 && (
            <Message error>Lånebeløpet må være større enn 10 000 kr</Message>
          )}
          {errorText && <Message error>{errorText}</Message>}
        </S.SummaryGridCol>
      </GridRow>
    </S.Wrapper>
  );
};

export const CarLoanCalculatorSerializer = (context: 'article' | 'stripped' = 'stripped') => {
  if (context === 'article') {
    return ({ value }: CarLoanCalculatorProps) => (
      <ArticleBlockContentGridCol>
        <CarLoanCalculatorBlock value={value} />
      </ArticleBlockContentGridCol>
    );
  }
  return ({ value }: CarLoanCalculatorProps) => <CarLoanCalculatorBlock value={value} />;
};
