import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { AbTestExperimentType } from '../../../../types/abTestExperiment';
import { useExperimentSeed } from '../../hooks/useExperimentSeed';
import { LoaderPage } from '../LoaderPage';
import { populateVariantsWithBinMaps } from './calculateABTestExperimentBins';
import { resolveDocumentDataUrl } from '../../hooks/useDocumentUrl';
import useSelector from '../../redux/typedHooks';
import { useGTMDataLayer } from '../../hooks/useGTMDataLayer';
import { NAFGTMEvent } from '../../../../types/GTM/NAFGTMEvent';

interface Props {
  data: AbTestExperimentType;
}

export const ABTestExperiment = ({ data }: Props) => {
  const persistedExperimentSeed = useExperimentSeed();
  const history = useHistory();
  const dataLayer = useGTMDataLayer();
  const { mappedCategories } = useSelector((state) => state.sitestructure);
  const [didSendtrackingEvent, setDidSendTrackingEvent] = useState(false);

  // Decide which test group the user falls into and redirect to the correct one.
  useEffect(() => {
    if (
      persistedExperimentSeed &&
      data &&
      history &&
      mappedCategories &&
      dataLayer &&
      didSendtrackingEvent === false &&
      setDidSendTrackingEvent
    ) {
      const bins = populateVariantsWithBinMaps(data.variants);
      Object.values(bins).forEach((bin) => {
        if (persistedExperimentSeed >= bin.lowerLimit && persistedExperimentSeed <= bin.upperLimit) {
          const redirectUrl = resolveDocumentDataUrl(bin.reference, mappedCategories);
          dataLayer.push({
            event: NAFGTMEvent.abExperimentParticipant,
            experimentId: data.experimentId,
            variantId: bin.variantId,
          });
          setDidSendTrackingEvent(true);
          history.replace(redirectUrl);
        }
      });
    }
  }, [
    data,
    persistedExperimentSeed,
    history,
    dataLayer,
    mappedCategories,
    didSendtrackingEvent,
    setDidSendTrackingEvent,
  ]);

  return <LoaderPage />;
};
