import React, { ReactNode } from 'react';
import { StyledArticleContentGrid } from '../../../pages/Article/Styles';

interface ContainerSerializerProps {
  children: ReactNode | ReactNode[];
}
export const ContainerSerializer = (context: 'article' | 'stripped' = 'stripped') => {
  if (context === 'article') {
    return ({ children }: ContainerSerializerProps) => <StyledArticleContentGrid>{children}</StyledArticleContentGrid>;
  }
  return ({ children }: ContainerSerializerProps) => children;
};
