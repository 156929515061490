import { useEffect } from 'react';

// The seed should be preserved, to ensure a consistent user experience across sessions
export const useExperimentSeed = () => {
  const isClient = typeof window !== 'undefined';
  const generatedRandomSeed = isClient
    ? parseFloat(localStorage?.getItem('ab-experiment-seed')) || Math.random()
    : null;
  useEffect(() => {
    localStorage.setItem('ab-experiment-seed', generatedRandomSeed.toString(10));
  }, [isClient, generatedRandomSeed]);

  return generatedRandomSeed || null;
};
