import { format, parseISO, setMonth, setDay } from 'date-fns';
import { nb } from 'date-fns/locale';
import { monthMap } from './createNorwegianDateString';

export const getDayName = (dayNumber?: number): string => {
  const day = dayNumber !== undefined ? setDay(new Date(), dayNumber) : new Date();

  const dayName = format(day, 'EEEE', { locale: nb });
  return `${dayName.charAt(0).toUpperCase()}${dayName.slice(1)}`;
};

export const getMonthName = (monthNumber?: number): string => {
  const month = monthNumber ? setMonth(new Date(), monthNumber) : new Date();

  return format(month, 'MMMM', { locale: nb });
};

export const getTwoDigitDay = (dayNumber?: number): string => {
  const day = dayNumber || new Date().getDate();

  return `0${day}`.slice(-2);
};

export const getCurrentDate = (): string => {
  const date = new Date();

  return `${getTwoDigitDay()}. ${getMonthName()} ${date.getFullYear()}`;
};

export const getSeason = (date: string): string | null => {
  const parsedDate = new Date(date);
  const month = parsedDate.getMonth();
  if (month < 3 || month > 8) return `vinteren ${parsedDate.getFullYear()}`;
  if (month >= 3 && month <= 8) return `sommeren ${parsedDate.getFullYear()}`;
  return null;
};

export const isFuture = (date: string): boolean => {
  const parsedDate = new Date(date);
  const now = new Date();
  return parsedDate > now;
};

export const getMonthAndYear = ({ month, year }: { month: number; year: number }): string => {
  const monthName = monthMap[month - 1];
  return `${monthName} ${year}`;
};

export const getTimeOfDay = (date: string): string => {
  const parsedDate = parseISO(date);
  return format(parsedDate, 'HH:mm');
};

export const formatDate = (date: string, formatString = 'dd.MM.yyyy') => {
  try {
    const parsedDate = new Date(date);
    return format(parsedDate, formatString);
  } catch (e) {
    return '';
  }
};

export const formatDateWithMonthName = (date: string) => {
  const parsedDate = new Date(date);
  return format(parsedDate, 'd. MMMM yyyy', { locale: nb });
};
