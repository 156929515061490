import React from 'react';
import loadable from '@loadable/component';
import { ChargeTestType, DocumentTypeLabel, RangeTestType } from '../../../types/testType';
import { useDocument } from '../hooks/useDocument';
import { useIsDocumentLocationUrlValid } from '../hooks/useIsDocumentLocationUrlValid';
import { useAuth0Token } from '../hooks/useAuth0Token';
import { LoaderPage } from '../pages/LoaderPage';
import { ArticleType, ProductArticleType } from '../../../types/articleType';
import { BenefitsType } from '../../../types/benefitsType';
import { CenterProductArticleType } from '../../../types/centerProductArticleType';
import { ProductPageAppPropertiesType, TopicPageAppPropertiesType } from '../../../types/CategoryAppType';
import { FaqArticleType } from '../../../types/faqArticleType';
import { AbTestExperimentType } from '../../../types/abTestExperiment';
import { ABTestExperiment } from '../pages/ABTestExperiment';
import { NotFound } from '../loadable-elements/NotFound';

const TopicPageApp = loadable(() => import('../pages/TopicPageApp/TopicPageApp'), {
  resolveComponent: (components) => components.TopicPageApp,
});

const Article = loadable(() => import('../pages/Article/Article'));
const FAQArticle = loadable(() => import('../pages/FAQ/FAQArticle'));
const ProductArticle = loadable(() => import('../pages/ProductArticle/ProductArticle'));
const BenefitArticle = loadable(() => import('../pages/BenefitArticle'));

// Get the document, check the whether the location is valid, and render the correct document template.
export const DocumentTypeSwitch = ({
  slug,
  omitFromLocationValidation,
}: {
  slug: string;
  omitFromLocationValidation?: boolean;
}) => {
  const { simpleToken } = useAuth0Token();
  const { meta, data } = useDocument(slug, simpleToken);
  const isDocumentLocationUrlValid = useIsDocumentLocationUrlValid(slug);
  const isValid = omitFromLocationValidation || isDocumentLocationUrlValid;
  if (
    data &&
    ((data as CenterProductArticleType).type === DocumentTypeLabel.nafCenterProductArticle ||
      ((data as ArticleType).category && (data as ArticleType).category?.name === 'NAF Senter'))
  ) {
    // Regardless of data - If the document does belong in the NAF Center tree, render 404 page
    return <NotFound />;
  }

  if (data && isValid) {
    switch (
      (
        data as
          | ArticleType
          | ProductArticleType
          | RangeTestType
          | ChargeTestType
          | BenefitsType
          | TopicPageAppPropertiesType
          | ProductPageAppPropertiesType
          | FaqArticleType
          | AbTestExperimentType
      )?.type
    ) {
      case DocumentTypeLabel.nafnoArticle:
        return <Article article={data as ArticleType} />;
      case DocumentTypeLabel.nafnoNewsArticle:
        return <Article article={data as ArticleType} />;
      case DocumentTypeLabel.travelSuggestion:
        return <Article article={data as ArticleType} />;
      case DocumentTypeLabel.faq:
        return <FAQArticle article={data as FaqArticleType} />;
      case DocumentTypeLabel.nafnoProductArticle:
        return <ProductArticle productArticle={data as ProductArticleType} />;
      case DocumentTypeLabel.nafMembershipBenefit:
        return <BenefitArticle benefitArticle={data as BenefitsType} />;
      case DocumentTypeLabel.topicPage: {
        const topicPage = data as unknown as TopicPageAppPropertiesType;
        return <TopicPageApp data={topicPage.category} asArticleData={topicPage} />;
      }
      case DocumentTypeLabel.productPage: {
        const productPage = data as unknown as ProductPageAppPropertiesType;
        return <TopicPageApp data={productPage.category} asArticleData={productPage} />;
      }
      case DocumentTypeLabel.abTestExperiment: {
        return <ABTestExperiment data={data as unknown as AbTestExperimentType} />;
      }
      default:
        return null;
    }
  }
  if (!data && meta.isUpdating === true) {
    // If there is no data, but the document is loading -> render loader
    return <LoaderPage />;
  }
  if ((!isValid || !data) && meta.isUpdating === false) {
    // Regardless of data - If the document is not loading and the current location is invalid, render 404 page
    return <NotFound />;
  }
  return null;
};

// ArticleType | ProductArticleType | RangeTestType | ChargeTestType | BenefitsType
