import React from 'react';
import { createStore, GlobalState, StateMachineProvider } from 'little-state-machine';

import Auth0ProviderWithHistory from './Auth0ProviderWithHistory';
import { AppInsightsContextProvider } from './AppInsightsContextProvider';
import { useAnchors } from '../hooks/useAnchors';
import { useVirtualPageViewTracking } from '../hooks/useVirtualPageViewTracking';
import { useGTMInitializer } from '../hooks/useGTMInitializer';
import { ThemeContextProvider } from './ThemeContextProvider';
import { ThemedApp } from './ThemedApp';
import { ThemeVariants } from '../../../types/themes';
import { TokenProvider } from '../hooks/useAuth0Token';

export type FormNames = {
  [key in keyof GlobalState]?: GlobalState[key];
};

createStore(
  {
    membershipForm: {},
    membersForm: {},
    householdMemberForm: {},
    internationalDrivingPermitForm: {},
    idpCustomerForm: {},
    addonsForm: {},
  },
  {},
);

export const App = ({ initialTheme, abTestWeight }: { initialTheme?: ThemeVariants | null; abTestWeight?: number }) => {
  useGTMInitializer();
  useVirtualPageViewTracking();
  useAnchors();

  return (
    <AppInsightsContextProvider>
      <Auth0ProviderWithHistory>
        <TokenProvider>
          <StateMachineProvider>
            <ThemeContextProvider>
              <ThemedApp initialTheme={initialTheme} abTestWeight={abTestWeight} />
            </ThemeContextProvider>
          </StateMachineProvider>
        </TokenProvider>
      </Auth0ProviderWithHistory>
    </AppInsightsContextProvider>
  );
};
