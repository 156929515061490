import React from 'react';
import { Button } from '@naf/button';
import styled from 'styled-components';
import { useHistory, useLocation } from 'react-router';
import { InternalGlobalCTAType } from './index';
import { useDocumentUrlWithNode } from '../../../../../../hooks/useDocumentUrl';
import useSelector from '../../../../../../redux/typedHooks';
import { useGTMDataLayer } from '../../../../../../hooks/useGTMDataLayer';

interface Props {
  data: InternalGlobalCTAType['data'];
}
export const InternalLink = ({ data }: Props) => {
  const baseUrl = useSelector((state) => state.application.baseUrl);
  const history = useHistory();
  const location = useLocation();
  const dataLayer = useGTMDataLayer();
  const referenceUrl = useDocumentUrlWithNode(data.internalLink) || '';

  const handleClick = () => {
    // Handle tracking
    const urlObject = new URL(baseUrl);
    dataLayer?.push({
      event: 'cta_click',
      cta_text: data.buttonText,
      cta_location: location.pathname,
      cta_url: referenceUrl,
      cta_hostname: urlObject.hostname,
      ctaPosition: 'content',
    });
    // Handle blank link
    if (data.blank) {
      window.open(`${baseUrl}${referenceUrl}`);
    } else {
      history.push(referenceUrl);
    }
  };

  return (
    <Wrapper>
      <Button onClick={handleClick}>{data.buttonText}</Button>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  margin-bottom: 16px;
`;
